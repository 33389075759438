<template>
  <div class="login">
    <div class="logo"></div>
    <div class="login-form">
      <van-field
        v-model="phone"
        :left-icon="leftIcon1"
        autocomplete="off"
        type="digit"
        placeholder="输入手机号"
      />
      <van-field
        class="fieldCode"
        v-model="code"
        type="digit"
        autocomplete="off"
        :left-icon="leftIcon2"
        placeholder="短信验证码"
      >
        <template #button>
          <span class="sendCode" @click="getCode">{{ codeTile }}</span>
        </template>
      </van-field>
      <div class="desc">
        <img
          v-show="checkbox"
          src="../../assets/icon/checkbox.png"
          alt=""
          @click="checkbox = false"
        />
        <img
          v-show="!checkbox"
          src="../../assets/icon/checkbox1.png"
          alt=""
          @click="checkbox = true"
        />
        <span style="color: #aaaaaa; margin-top: 3px"
          >已阅读并同意
          <b style="color: #c17b15" @click="open(1)"
            >《全游云电脑用户服务协议》</b
          >及
          <b style="color: #c17b15" @click="open(2)">《用户隐私协议》</b>
        </span>
      </div>
      <van-button
        class="btn"
        round
        :color="disableColor"
        :loading="loading"
        loading-text="登录"
        block
        @click="submit"
        >登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { phoneReg } from '@/utils/reg.js'
import { mobileCode } from '@/api/user.js'
import { getParamsNew } from '@/utils/index.js'
import { setToken } from '@/utils/auth'
export default {
  data() {
    return {
      phone: '',
      code: '',
      codeTile: '发送验证码',
      checkbox: false,
      leftIcon1: require('@/assets/icon/phone.png'),
      leftIcon2: require('@/assets/icon/message.png'),
      loading: false,
      isAbled: false
    }
  },
  created() {
    const code = getParamsNew('code')
    console.log(code)
    if (code) {
      console.log('-----')
      this.$store.dispatch('getInfo', { code }).then((res) => {
        if (res.userToken) {
          setToken(res.userToken)
          this.$router.replace('/user/index')
        }
      })
    }
  },
  methods: {
    open(i) {
      if (i === 1) {
        this.$router.push('/userAgreement')
      }
      if (i === 2) {
        this.$router.push('/privacyAgreement')
      }
    },
    // 手机号验证
    validator() {
      if (!this.phone) {
        this.$toast('请输入手机号')
        return false
      } else if (phoneReg.test(this.phone)) {
        return true
      } else {
        this.$toast('手机号格式不正确')
        return false
      }
    },
    async submit() {
      if (this.validator() && this.code) {
        if (!this.checkbox) return this.$toast('先同意服务协议')
        this.loading = true
        this.$store
          .dispatch('login', {
            mobile: this.phone,
            smsCode: this.code
          })
          .then((res) => {
            this.loading = false
            if (res.status === 200) {
              this.$router.replace('/home')
            }
          })
          .catch(() => {
            this.loading = false
          })
      } else if (!this.code) {
        this.$toast('请输入验证码')
        return false
      }
    },
    async getCode() {
      if (this.isAbled) return false
      if (this.validator()) {
        this.isAbled = true
        const params = {
          mobile: this.phone
        }
        const res = await mobileCode(params)
        if (res.status === 200) {
          this.validateBtn()
        } else {
          this.$toast(res.msg)
        }
      }
    },
    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.isAbled = false
          this.codeTile = '发送验证码'
        } else {
          this.isAbled = true
          this.codeTile = time + 's'
          time--
        }
      }, 1000)
    }
  },
  computed: {
    disableColor() {
      if (this.phone === '' || this.code === '') {
        return '#413C33'
      } else {
        return '#CB7E0E'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/login-bg.png') no-repeat top center;
  background-size: 100% 100%;
}
.logo {
  position: absolute;
  top: 74px;
  left: 50%;
  transform: translateX(-50%);
  width: 67px;
  height: 55px;
  background: url('../../assets/icon/logo.png') no-repeat top center;
  background-size: 100% 100%;
}
.login-form {
  position: absolute;
  top: 186px;
  left: 50%;
  transform: translateX(-50%);
  width: 313px;
  .fieldCode {
    margin-top: 20px;
  }
  .sendCode {
    color: @text-color-1;
  }
  /deep/.van-field {
    height: 42px;
    border-radius: 42px;
    background-color: #413c33;
    align-items: center;
  }
  .desc {
    display: flex;
    margin-top: 10px;
    font-size: 13px;
    line-height: 18px;
    img {
      width: 22px;
      height: 22px;
      object-fit: cover;
    }
  }
  .btn {
    height: 42px;
    margin-top: 20px;
  }
}
</style>
